/* You can add global styles to this file, and also import other style files */

@import "_variables";

body.dark {
  @include darken();
}
body.light {
  @include lighten();
}

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--cards-dialogs);
  transition: 0.3s;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.hor {
  margin-left: 1.5em;
  margin-right: 1.5em;
  color: var(--text-color);
}

.sec-title {
  text-align: center;
  padding-top: 3em;
  color: var(--text-color);
}

.sub-title {
  color: #262626;
}

.date-loc {
  color: #404040;
}

.sub-title {
  color: #404040;
}
